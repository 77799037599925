export default {
    // config文件夹用于放配置文件,测试配置以 dev_ 开头
    dev_base_url: "http://10.0.0.222:8080",//用于测试 http://10.0.0.222:8080 http://wpt.caihonghd.com
    base_url: "http://wpt.caihonghd.com",//线上环境 https://wpt.caihonghd.com https://wpt.linlinfa.com
    dev_connection: "10.0.0.222:9371", // socket io 10.0.0.222:9371
    connection: "http://hm.caihonghd.com", // socket io https://hk.linlinfa.com/ http://hm.caihonghd.com
    //接口白名单（方法不加token）
    url_white_list: ['/hm/login/code','/hm/login/account','/hm/menu/footer','/hm/classify/getServeTypeList','/hm/company/register','/hm/login/refresh'],
    // 刷新页面黑名单，刷新就关闭
    router_black_list: ['commodityEdit'],
    dev_debug: true,
    debug: false,
    dev_page: [2, 10, 20, 50, 100, 200, 500, 1000 ], // 分页线上和测试不一样
    page: [20, 50, 100, 200, 500, 1000],
}