import Page from "@/components/Page/Page"
import Icon from "@/components/Icon/Icon"
import Layout from "@/components/Layout/Layout"
import Table from "@/components/Table/Table.jsx"
import Form from "@/components/Form/Form.jsx"
import Button from "@/components/Button/Button"
import UploadImg from "@/components/Upload/uploadImg.jsx"
import AuthCodeDown from '@/components/Managment/AuthCodeDown/index'
import Search from "@/components/Search/Search";
import selectHouseList from "@/components/areaModal/selectHouseList";
import printTable from '@/components/Managment/PrintTable/index';
import printMerge from "@/components/Managment/PrintTable/printMerge";
const components = {
  chButton: Button,
  chPage: Page,
  chIcon: Icon,
  chLayout: Layout,
  chTable: Table,
  chForm: Form,
  chUploadImg: UploadImg,
  chAuthCodeDown: AuthCodeDown,
  chSearch: Search,
  chSelectHouseList: selectHouseList,
  chPrintTable: printTable,
  chPrintMerge: printMerge,
}

export default components
