<template>
  <!--传入房间选择 -->
  <div>
    <el-dialog
      title="选择区域"
      :visible.sync="dialogVisible"
      :style="{ minWidth: '400px' }"
      class="area-dialog"
      :modal="false"
      :show-close="false"
    >
      <div class="main">
        <div class="search" v-if="search">
          <el-input placeholder="请输入关键字" v-model.trim="inputText">
            <el-button slot="append" icon="el-icon-search"></el-button>
          </el-input>
        </div>

        <div class="content" v-if="currentData">
          <!-- 多选 -->
          <div v-if="multiple">
            <div class="item">
              <!-- 社区 -->
              <div class="row" @click.stop="clickFold(currentData)">
                <i
                  class="el-icon-arrow-right toshow fristRow"
                  :class="{ rotate90: currentData.fold }"
                ></i>
                <checkbox
                  @change="changeCommunity(currentData)"
                  v-model="currentData.check"
                  :indeterminate="currentData.indeterminate"
                  class="myCheckBox"
                ></checkbox
                >{{ currentData.title }}
              </div>
              <div v-show="currentData.fold" class="foldChild">
                <div
                  v-for="(area, index) in currentData.childrenList"
                  :key="index"
                >
                  <!-- 区域 -->
                  <div @click.stop="clickFold(area)" class="textNode">
                    <span>
                      <i
                        class="el-icon-arrow-right toshow"
                        :class="{ rotate90: area.fold }"
                      ></i>
                      <checkbox
                        @change="changeArea(area)"
                        v-model="area.check"
                        :indeterminate="area.indeterminate"
                        class="myCheckBox"
                      ></checkbox
                      >{{ area.title }}
                    </span>
                  </div>
                  <div v-show="area.fold" class="foldChild">
                    <div
                      v-for="(build, index) in area.childrenList"
                      :key="index"
                    >
                      <!-- 楼栋 -->
                      <div @click.stop="clickFold(build)" class="textNode">
                        <span>
                          <i
                            class="el-icon-arrow-right toshow"
                            :class="{ rotate90: build.fold }"
                          ></i>

                          <checkbox
                            @change="changeBuild(build)"
                            v-model="build.check"
                            :indeterminate="build.indeterminate"
                            class="myCheckBox"
                          ></checkbox
                          >{{ build.title }}
                        </span>
                      </div>
                      <div v-show="build.fold" class="lastChild">
                        <div
                          v-for="(house, index) in build.childrenList"
                          :key="index"
                          class="childrenList"
                        >
                          <!-- 房屋 -->
                          <!-- <div class="row textNode">
                          <div
                            class="row_right"
                            :class="{
                              row_check: house.check,
                            }"
                            @click.stop="
                              () => {
                                house.check = !house.check;
                                changeHouse(house);
                              }
                            "
                          >
                            <span class="row_text">
                              {{ house.roomNumber }}
                              {{
                                house.userName ? "-" + house.userName : ""
                              }}</span
                            >
                            <i
                              class="el-icon-check row_text"
                              v-show="house.check"
                            ></i>
                          </div>
                        </div> -->

                          <div class="textNode">
                            <checkbox
                              @change="changeHouse(house)"
                              v-model="house.check"
                              class="myCheckBox"
                            ></checkbox>
                            {{ house.roomNumber }}
                            {{ house.userName ? "-" + house.userName : "" }}
                          </div>
                          <div v-if="reduct">
                            <checkbox
                                @change="changeReduction(house)"
                                v-model="house.reductCheck"
                                class="myCheckBox"
                            ></checkbox>
                            {{ '不含滞纳金' }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 单选 -->
          <div v-else>
            <div class="item">
              <!-- 社区 -->
              <div
                class="row"
                @click.stop="currentData.fold = !currentData.fold"
              >
                <i
                  class="el-icon-arrow-right toshow fristRow"
                  :class="{ rotate90: currentData.fold }"
                ></i>
                {{ currentData.title }}
              </div>
              <div v-show="currentData.fold" class="foldChild">
                <div
                  v-for="(area, index) in currentData.childrenList"
                  :key="index"
                >
                  <!-- 区域 -->

                  <div @click.stop="area.fold = !area.fold" class="textNode">
                    <span>
                      <i
                        class="el-icon-arrow-right toshow"
                        :class="{ rotate90: area.fold }"
                      ></i>
                      {{ area.title }}
                    </span>
                  </div>
                  <div v-show="area.fold" class="foldChild">
                    <div
                      v-for="(build, index) in area.childrenList"
                      :key="index"
                    >
                      <!-- 楼栋 -->

                      <div
                        @click.stop="build.fold = !build.fold"
                        class="textNode"
                      >
                        <span>
                          <i
                            class="el-icon-arrow-right toshow"
                            :class="{ rotate90: build.fold }"
                          ></i>

                          {{ build.title }}
                        </span>
                      </div>
                      <div v-show="build.fold" class="lastChild">
                        <div
                          v-for="(house, index) in build.childrenList"
                          :key="index"
                        >
                          <!-- 房屋 -->
                          <div
                            class="row textNode"
                            @click.stop="clickHouse(house)"
                          >
                            <div
                              class="row_right"
                              :class="{
                                row_check:
                                  house.housingId == selectedData.housingId
                              }"
                            >
                              <span class="row_text">
                                {{ house.roomNumber }}
                                {{
                                  house.userName ? "-" + house.userName : ""
                                }}</span
                              >
                              <i
                                class="el-icon-check row_text"
                                v-show="
                                  house.housingId == selectedData.housingId
                                "
                              ></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="content" v-else v-loading="true"></div>
      </div>
      <div slot="footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="clickOk">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import checkbox from "./checkbox.vue";
// import _ from 'lodash'

export default {
  components: { checkbox },
  //reduct 滞纳金减免   carId  银行
  props:{
    houseList: {
      type: Array,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    reduct: {
      type: Boolean,
      default: false,
    },
    carId: {
      type: String,
      default: '',
    },
    search: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      dialogVisible: false,
      type: true,
      currentData: null, //当前列表显示数据
      selectedHouseIdList: [], //选中数据id
      sourceData: {}, //原始数据
      foldObj: null, //是否展开
      sourceList: [], //原有数据
      selectedData: {}, //单选选中数据
      inputText: "",
      timeout: null,
      reduction:[], //滞纳金
    };
  },
  watch: {
    //监听输入框的输入
    inputText() {
      //传入要防抖执行的函数，延时时间
      this.debounce(this.getSearchSuggest, 500);
    },
  },
  methods: {
    // 防抖函数
    debounce(fn, delay) {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        fn();
      }, delay);
    },
    //搜索
    getSearchSuggest() {
      let self = this;
      if (self.inputText.length) {
        let currentHouseList = [];
        for (let house of self.houseList) {
          if (
            (house.areaName != null &&
              house.areaName.indexOf(self.inputText) != -1) ||
            house.building.indexOf(self.inputText) != -1 ||
            house.roomNumber.indexOf(self.inputText) != -1 ||
            (house.userName != null &&
              house.userName.indexOf(self.inputText) != -1)
          ) {
            currentHouseList.push(house);
          }
        }
        let obj = {
          title: self.houseList[0].communityName,
          children: {},
        };
        for (let house of currentHouseList) {
          house.currentAreaId = house.areaId ? house.areaId : -1;
          if (!obj.children[house.currentAreaId]) {
            obj.children[house.currentAreaId] = {
              title: house.areaId == null ? "无区域" : house.areaName,
              children: {},
            };
          }
          if (!obj.children[house.currentAreaId].children[house.buildId]) {
            obj.children[house.currentAreaId].children[house.buildId] = {
              title: house.building,
              children: {},
            };
          }
          obj.children[house.currentAreaId].children[house.buildId].children[
            "wu" + house.housingId
          ] = house;
        }
        self.sourceList = currentHouseList;
        self.sourceData = obj;
      } else {
        let obj = {
          title: self.houseList[0].communityName,
          children: {},
        };
        for (let house of self.houseList) {
          house.currentAreaId = house.areaId ? house.areaId : -1;
          if (!obj.children[house.currentAreaId]) {
            obj.children[house.currentAreaId] = {
              title: house.areaId == null ? "无区域" : house.areaName,
              children: {},
            };
          }
          if (!obj.children[house.currentAreaId].children[house.buildId]) {
            obj.children[house.currentAreaId].children[house.buildId] = {
              title: house.building,
              children: {},
            };
          }
          obj.children[house.currentAreaId].children[house.buildId].children[
            "wu" + house.housingId
          ] = house;
        }
        self.sourceList = self.houseList;
        self.sourceData = obj;
      }
      self.getData();
    },
    //页面进入(selectedList:选中数据,filterList:过滤房间数据,multiple:是否多选，)
    show(selectedList = [], filterList = []) {
      let self = this;
      self.currentData = null;
      self.foldObj = null;
      self.inputText = "";
      self.selectedData = {};
      self.selectedHouseIdList = [];
      self.reduction = [];
      if (self.houseList && self.houseList.length > 0) {
        let houseList = self.houseList;
        if (filterList && filterList.length > 0) {
          houseList = houseList.filter((house) => {
            return filterList.indexOf(house.housingId) == -1;
          });
        }

        let obj = {
          title: houseList[0].communityName,
          children: {},
        };
        for (let house of houseList) {
          house.currentAreaId = house.areaId ? house.areaId : -1;
          if (!obj.children[house.currentAreaId]) {
            obj.children[house.currentAreaId] = {
              title: house.areaId == null ? "无区域" : house.areaName,
              children: {},
              id: house.currentAreaId
            };
          }
          if (!obj.children[house.currentAreaId].children[house.buildId]) {
            obj.children[house.currentAreaId].children[house.buildId] = {
              title: house.building,
              children: {},
              id: house.buildId
            };
          }
          obj.children[house.currentAreaId].children[house.buildId].children[
          "wu" + house.housingId
              ] = house;

        }
        self.sourceList = houseList;
        self.sourceData = obj;

        self.selectedHouseIdList = selectedList;
        if (selectedList.length && !self.multiple) {
          for (let house of houseList) {
            if (house.housingId == selectedList[0]) {
              self.selectedData = house;
              break;
            }
          }
        }
        self.getData();
      }
      self.dialogVisible = true;
    },

    // 点击社区
    changeCommunity(data) {

      let self = this;
      if (data.check) {
        let idList = [];
        self.sourceList.forEach((house) => {
          idList.push(house.housingId);
        });
        self.selectedHouseIdList = idList;


      } else {
        self.selectedHouseIdList = [];
      }
      self.getData();
    },

    //点击区域
    changeArea(data) {
      let self = this;
      let idList = [];
      self.sourceList.forEach((house) => {
        if (house.currentAreaId == data.id) {
          idList.push(house.housingId);
        }
      });
      if (data.check) {
        let currentIdList = self.selectedHouseIdList.concat(idList);
        let idSet = new Set(currentIdList);
        self.selectedHouseIdList = Array.from(idSet);
      } else {
        self.selectedHouseIdList = self.selectedHouseIdList.filter(
          (housingId) => {
            return idList.indexOf(housingId) == -1;
          }
        );
      }
      self.getData();
    },

    // 点击楼栋
    changeBuild(data) {
      let self = this;
      let idList = [];
      self.sourceList.forEach((house) => {
        if (house.buildId == data.id) {
          idList.push(house.housingId);
        }
      });
      if (data.check) {
        let currentIdList = self.selectedHouseIdList.concat(idList);
        let idSet = new Set(currentIdList);
        self.selectedHouseIdList = Array.from(idSet);
      } else {
        self.selectedHouseIdList = self.selectedHouseIdList.filter(
          (housingId) => {
            return idList.indexOf(housingId) == -1;
          }
        );
      }
      self.getData();
    },

    // 点击房屋
    changeHouse(data) {
      let self = this;
      if (data.check) {
        self.selectedHouseIdList.push(data.housingId);
      } else {
        self.selectedHouseIdList = self.selectedHouseIdList.filter(
          (housingId) => {
            return housingId != data.housingId;
          }
        );
      }
      self.getData();
    },
    // 滞纳金减免
    changeReduction(data){
      let self = this;
      if(data.reductCheck){
        self.reduction.push(data.housingId);
        if(!this.carId){
          this.$confirm("请先选择代扣银行", "提示", {
            confirmButtonText: "确定",
            type: "warning",
          }).then(() => {
            self.reduction =  self.reduction.filter(item => item !== data.housingId);
            self.getData();
          }).catch(()=> {
            self.reduction =  self.reduction.filter(item => item !== data.housingId);
            self.getData();
          })
          return
        }
        this.$curl
            .get(`/client/cliBankAgent/getLateFee/${data.housingId}/${this.carId}`)
            .then((res) => {
              let message = "滞纳金减免:" +res.data
              this.$confirm(message, "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              }).then(() => {
              }).catch(() => {
                self.reduction =  self.reduction.filter(item => item !== data.housingId);
                self.getData();
                this.$message({
                  type: "info",
                  message: "已取消",
                });
              });
            });
      } else {
        self.reduction = self.reduction.filter(
            (housingId) => {
              return housingId != data.housingId;
            }
        );
      }
      self.getData();
    },
    // 点击折叠
    clickFold(data) {
      data.fold = !data.fold;
      this.foldObj[data.index] = data.fold;
    },

    // 点击确定
    async clickOk() {
      let self = this;
      await this.resetInputText()
      if (self.multiple) {
        let selectedHosuseList = [];
        if (self.selectedHouseIdList.length > 0) {
          selectedHosuseList = self.sourceList.filter((house) => {
            return self.selectedHouseIdList.indexOf(house.housingId) != -1;
          });
        }
        selectedHosuseList.reduction = this.reduction
        self.dialogVisible = false;
        return self.$emit("on-back", selectedHosuseList);
      } else {
        let data = null;
        self.selectedData.reduction = this.reduction
        if (self.selectedData.housingId) {
          data = self.selectedData;
        }
        self.dialogVisible = false;
        return self.$emit("on-back", data);
      }
    },

    // 单选点击房屋
    clickHouse(data) {
      this.selectedData = data;
      let list = [];
      list.push(data.housingId);
      this.selectedHouseIdList = list;
    },

    //拼接数据
    getData() {
      let self = this;
      let obj = JSON.parse(JSON.stringify(self.sourceData));
      let index = 0;
      // 用于处理数据替换展开问题
      let foldObj = {};
      let currentObj = {
        title: obj.title,
        childrenList: [],
        indeterminate: false,
        check: false,
        index: index,
      };
      if (!self.foldObj) {
        currentObj.fold = true;
        foldObj[index] = true;
      } else {
        currentObj.fold = self.foldObj[index];
      }

      let areaCheckCount = 0;
      for (let areakey in obj.children) {
        index++;
        let areaObj = {
          title: obj.children[areakey].title,
          childrenList: [],
          indeterminate: false,
          id: areakey,
          check: false,
          index: index,
        };
        if (self.foldObj) {
          areaObj.fold = self.foldObj[index];
        } else {
          areaObj.fold = true;
          foldObj[index] = true;
        }

        let buildCheckCount = 0;
        let buildCount = 0;
        for (let buildKey in obj.children[areakey].children) {
          buildCount++;
          index++;
          let buildObj = {
            title: obj.children[areakey].children[buildKey].title,
            childrenList: [],
            id: buildKey,
            indeterminate: false,
            check: false,
            reductCheck: false,
            index: index,
          };
          if (self.foldObj) {
            buildObj.fold = self.foldObj[index];
          } else {
            buildObj.fold = false;
            foldObj[index] = false;
          }
          let houseCheckCount = 0;
          let houseCount = 0;
          for (let houseKey in obj.children[areakey].children[buildKey]
            .children) {
            houseCount++;
            if (
              self.selectedHouseIdList &&
              self.selectedHouseIdList.length > 0 &&
              self.selectedHouseIdList.indexOf(
                obj.children[areakey].children[buildKey].children[houseKey]
                  .housingId
              ) != -1
            ) {
              obj.children[areakey].children[buildKey].children[
                houseKey
              ].check = true;
              houseCheckCount++;
            } else {
              obj.children[areakey].children[buildKey].children[
                houseKey
              ].check = false;
            }
            if (
                self.reduction &&
                self.reduction.length > 0 &&
                self.reduction.indexOf(
                    obj.children[areakey].children[buildKey].children[houseKey]
                        .housingId
                ) != -1
            ) {
              obj.children[areakey].children[buildKey].children[
                  houseKey
                  ].reductCheck = true;
              houseCheckCount++;
            } else {
              obj.children[areakey].children[buildKey].children[
                  houseKey
                  ].reductCheck = false;
            }
            buildObj.childrenList.push(
              JSON.parse(
                JSON.stringify(
                  obj.children[areakey].children[buildKey].children[houseKey]
                )
              )
            );
          }
          if (houseCheckCount > 0) {
            if (houseCheckCount == houseCount) {
              buildObj.check = true;
              buildCheckCount++;
            } else {
              buildObj.indeterminate = true;
            }
          }
          areaObj.childrenList.push(buildObj);
        }

        if (buildCheckCount > 0) {
          if (buildCheckCount == buildCount) {
            areaObj.check = true;
            areaCheckCount++;
          } else {
            areaObj.indeterminate = true;
          }
        }
        currentObj.childrenList.push(areaObj);
      }

      if (areaCheckCount > 0) {
        if (areaCheckCount == currentObj.childrenList.length) {
          currentObj.check = true;
        } else {
          currentObj.indeterminate = true;
        }
      }
      currentObj.childrenList.sort((a,b) => a.id-b.id).map(item => {
        item.childrenList.sort((a, b) => {
          if(isNaN(parseInt(a.title) - parseInt(b.title))){
            return a.title.localeCompare(b.title,'zh-CN')
          }
          return  parseInt(a.title) - parseInt(b.title)
        }).map(item => {
          item.childrenList.sort((a, b) => {
            if(isNaN(parseInt(a.title) - parseInt(b.title))){
              return a.roomNumber.localeCompare(b.roomNumber,'zh-CN')
            }
            return parseInt(a.roomNumber) - parseInt(b.roomNumber)
          })
        })
      })
      self.currentData = currentObj;
      if (!self.foldObj) {
        self.foldObj = foldObj;
      }
    },

    // 解决 多选的时候 全选所有 然后搜索 某一户 结果导出的只有这一户
    // 方法 点确认的时候 重置搜索框
    async resetInputText(){
      let self = this;
      if (self.multiple) {
        self.inputText = ''
        let obj = {
          title: self.houseList[0].communityName,
          children: {},
        };
        for (let house of self.houseList) {
          house.currentAreaId = house.areaId ? house.areaId : -1;
          if (!obj.children[house.currentAreaId]) {
            obj.children[house.currentAreaId] = {
              title: house.areaId == null ? "无区域" : house.areaName,
              children: {},
            };
          }
          if (!obj.children[house.currentAreaId].children[house.buildId]) {
            obj.children[house.currentAreaId].children[house.buildId] = {
              title: house.building,
              children: {},
            };
          }
          obj.children[house.currentAreaId].children[house.buildId].children[
          "wu" + house.housingId
              ] = house;
        }
        self.sourceList = self.houseList;
        self.sourceData = obj;
        await self.getData()
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.area-dialog {
  z-index: 99 !important;
}
.fristRow {
  margin-top: 9px;
}
.search {
  height: 50px;
}
.main {
  width: 100%;
  height: 570px;
  margin-left: 50px;
}
.content {
  width: 100%;
  height: calc(100vh - 450px);
  margin-right: 6px;
  cursor: pointer;
  overflow: auto;
}
.item {
  height: 26px;
}
.row {
  display: flex;
}
.row_right {
  float: right;
  width: calc(100% - 30px);
  border-left: 4px #fff solid;
}
.row_text {
  margin-left: 5px;
}
.row_check {
  border-left: 4px #409eff solid;
  background: #b3d9ff4d;
  color: #409eff;
}
.myCheckBox {
  margin: 0 2px;
}
.foldChild {
  padding-left: 18px;
}
.lastChild {
  padding-left: 30px;
  width: 50%;
  .childrenList{
    display: flex;
    justify-content: space-between;
  }
}
.textNode {
  margin-top: 5px;
}
.toshow {
  font-size: 16px;
  width: 18px;
  height: 18px;
  line-height: 20px;
  transition: transform 0.2s;
}
.rotate90 {
  transform: rotate(90deg);
}
</style>
