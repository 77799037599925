<template>
  <div class="checkbox-wrap" @click.stop="handleClick">
    <div class="left" :class="isChecked || indeterminate ? 'box-click' : ''">
      <transition name="fade">
        <div class="hook" v-if="isChecked"></div>
      </transition>
      <div class="indet" v-show="indeterminate"></div>
    </div>
    <div class="right">{{ label }}</div>
  </div>
</template>
<script>
export default {
  props: ["label", "value", "indeterminate"],
  model: {
    prop: "value",
    event: "change",
  },
  data() {
    return {
      isChecked: false,
    };
  },
  watch: {
    value(val) {
      this.isChecked = val;
    },
  },
  mounted() {
    let self = this;
    self.$nextTick(() => {
      if (self.value) {
        self.isChecked = true;
      } else {
        self.isChecked = false;
      }
    });
  },
  methods: {
    handleClick() {
      this.isChecked = !this.isChecked;
      this.$emit("change", this.isChecked);
    },
  },
};
</script>

 <style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.checkbox-wrap {
  height: 14px;
  display: inline-block;
  vertical-align: center;
  margin-bottom: 5px;
}
.left {
  height: 14px;
  width: 14px;
  border-radius: 3px;
  background-color: #ffffff;
  display: inline-block;
  border: 1.2px solid #cccccc;
  box-sizing: border-box;
}
.right {
  margin-left: 5px;
  padding-right: 5px;
  display: inline;
  vertical-align: top;
  line-height: 14px;
  color: #66757f;
  font-size: 14px;
}
.box-click {
  background-color: #1da1f2;
  border: 0.5px solid #cccccc;
}
.hook {
  margin-left: 4px;
  margin-top: 1px;
  width: 3px;
  height: 7px;
  border-right: 1.3px solid #ffffff;
  border-bottom: 1.3px solid #ffffff;
  transform: rotate(40deg);
}
.indet {
  padding-top: 5px;
  width: 7px;
  margin-left: 3px;
  border-bottom: 1px solid #ffffff;
}
.checkbox-wrap:hover {
  cursor: pointer;
}
</style>
